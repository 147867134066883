import React from 'react';


const Outro = () => {
    return (
        <div className='pt-32'>
        <div className='text-xl text-nearBlack font-HalyardDisplay font-normal leading-normal content-end uppercase tracking-wide'>
                currently
         </div>
         <hr className="h-px my-4 bg-gray-800 border-1"></hr>
            <div className=' text-2xl text-nearBlack font-Stratos leading-relaxed  max-w-3xl'>
                <h3 className='py-2'>Michael Pecirno is a designer and researcher but also an occasional writer, strategist, and maker – depending on day of the week.</h3>
                <h3 className='py-2'>His experience involves researching, analysing, and designing creative things for all kinds of problems and clients, from cities to digital apps to art galleries. He was trained in experience design and architecture at the Royal College of Art in the UK and the University of Illinois at Chicago in the USA.</h3>
                <h3 className='py-2'>Michael can jump from ethnography to cartography to scenography in the blink of an eye. He’s willing to learn holography should the right project or technology come along.</h3>
                <br />
                <h3 className='py-2 underline text-base'>Currently </h3>
                <h3 className='py-2'> Sustainable Futures Lead with global design studio <a
                        className="cursor-pointer"
                        href="https://www.hassellstudio.com"
                        target="_blank"
                        rel="noreferrer"
                    >Hassell</a>
                </h3>
                <h3 className='py-2 underline text-base'>Previously
                </h3>
                <ul className='py-2 text-base'>
                    <li>Takram</li>
                    <li>Fjord</li>
                    <li>Foster + Partners</li>
                    <li>Bruce Mau Design</li>
                </ul>
            </div>
        </div>
    );
}

export default Outro;

