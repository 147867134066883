import React from 'react';


const Intro = () => {

    return (
        <div>
            <div className='text-4xl text-nearBlack font-Stratos font-normal leading-normal max-w-4xl mb-80' >
                Michael Pecirno is a designer and researcher whose work investigates the relationship between people, spaces, and technology.
                <br />
                <br />
                I make things that help make sense of the world.
            </div>
            <div className='text-xl text-nearBlack font-HalyardDisplay font-normal leading-normal content-end uppercase tracking-wide'>
                recent projects
            </div>
        </div>
        )
}

export default Intro;
