import React, { useState } from 'react';

interface Props {
  text: string;
  imageUrl: string;
  imageAlt: string;
}

const HoverImage: React.FC<Props> = ({ text, imageUrl, imageAlt }) => {
  const [showImage, setShowImage] = useState(false);

  const handleMouseEnter = () => {
    setShowImage(true);
  };

  const handleMouseLeave = () => {
    setShowImage(false);
  };

  return (
    <div className="inline" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <p className="font-medium inline font-Stratos">{text}</p>
      {showImage && (
        <div
          style={{
            position: 'fixed',
            bottom: '6rem',
            right: '6rem',
            width: '500px',
            height: '350px',
          }}
        >
          <img src={imageUrl} alt={imageAlt} />
        </div>
      )}
    </div>
  );
};

export default HoverImage;
