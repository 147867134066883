import React, { useState, MouseEvent, useRef } from 'react'
import Intro from './components/intro';
import Recent from './components/recent';
import Outro from './components/outro';

function App() {

  let mouseX = 100
  let mouseY = 1

  return (
    <div id="site" className="p-24" style={{
        backgroundColor: 'hsla(0,0%,90%,1)',
        backgroundImage: `radial-gradient(at ${mouseX}% ${mouseY}%, hsla(180,100%,86%,1) 0px, transparent 50%)`, 
    }} 
    >
      <Intro />
      <Recent projectName='default'/>
      <Outro />
    </div>
  );  
}

export default App;
